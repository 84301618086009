:root {
  font-family: source-serif-pro, sans-serif;
  color: white !important;
}
body {
  margin: 0;
  padding: 0;
}
h1 {
  padding: 0;
  margin: 0.25em;
  font-size: 2em;
}
.explorer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40%;
  max-width: min(95%, 60em);
  margin: 0 auto;
  margin-top: 1em;
  border-radius: 1rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(18, 44, 120, 1) 100%
  );
  box-shadow: 0em 0.6em 3em 0.3em rgba(2, 0, 36, 1);
}
.search-wrapper {
  width: 95%;
}
@media screen and (max-width: 460px) {
  .explorer-wrapper {
    margin: 0;
    padding: 0;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
  }
}
.search-wrapper form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75em;
  gap: 0.5em;
  height: 2.5rem;
  max-width: 100%;
}

.search-wrapper form input {
  font-family: source-serif-pro, sans-serif;
  font-size: 1.5em;
  padding: 0.5em 0.75em;
  height: 100%;
  width: max(50%, 20em);
  border-radius: 100rem;
}
.search-wrapper form button {
  border-radius: 100rem;
  height: 100%;
  aspect-ratio: 1/1;
  border: none;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  background-color: white;
}
.search-wrapper button:hover,
.search-wrapper:focus {
  background-color: #a5b4fc;
}
.factsheet-wrapper {
  width: 75%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0em;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 1em;
}
.factsheet-wrapper h1 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
}
.factsheet-wrapper h5 {
  margin: 0;
  font-size: 1.1em;
  font-weight: 300;
  margin-bottom: 0.5em;
}
.quickfacts-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 0.5em;
  width: 80%;
}
.quickfact-wrapper {
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  flex: 1 1em;
  grid-gap: 0;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.2);
}
.quickfact-wrapper h4,
.quickfact-wrapper h3 {
  margin: 0em;
}
.quickfact-wrapper h3 {
  font-size: 2em;
}
.quickfact-wrapper h4 {
  font-weight: 300;
  font-size: 1em;
}
.piechart {
  min-width: 17.5em;
  max-width: 80%;
  margin-top: 1em;
}
text {
  color: white !important;
  fill: white;
}
.h-stack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
